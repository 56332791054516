<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div class="col ">
							<div class=" text-2xl text-primary font-bold">
								Book your bed space
							</div>
							<div class=" text-lg">
								Application processing fee: GHS {{ props.pageData.booking_fee }}
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section v-if="formData.transaction_id" class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="col-12 md:col-12">
						<div class="card px-4">
							<div class="font-bold mb-2 font-bold text-xl">
								Payment authorization request sent ...!
							</div>
							<div class="text-purple-500 text-md">
								<ul>
									<li>
										You will receive a prompt on your mobile device to complete payment.
									</li>
									<li>
										Enter your wallet PIN to confirm this payment.
									</li>
								</ul>
							</div>
						</div>
						<div class="mt-3 text-center">
							<Button label="I have Paid" class="p-button-primary px-5" :loading="formData.checking"
								@click="confirmPayment()" />
						</div>
					</div>

				</div>
			</div>
		</section>
		<section v-else class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-12 sm:col-12 comp-grid">
						<div>
							<form ref="observer" tag="form" @submit.prevent="submitForm()"
								:class="{ 'card': !isSubPage }" class="">
								<div class="card nice-shadow-5 mb-3">
									<div class="font-bold text-lg text-grey-300">
										Personal Information
									</div>
									<Divider />
									<div class="grid mt-3">
										<div class="col-12 md:col-4">
											<div class="mb-2 font-bold"> First Name * </div>
											<div id="ctrl-first_name-holder">
												<InputText ref="ctrlfirst_name" v-model.trim="formData.first_name"
													label="First Name" type="text" placeholder="Enter First Name"
													class=" w-full" :class="getErrorClass('first_name')">
												</InputText>
											</div>
										</div>
										<div class="col-12 md:col-4">
											<div class="mb-2 font-bold"> Middle Name </div>
											<div id="ctrl-middle_name-holder">
												<InputText ref="ctrlmiddle_name" v-model.trim="formData.middle_name"
													label="Middle Name" type="text" placeholder="Enter Middle Name"
													class=" w-full" :class="getErrorClass('middle_name')">
												</InputText>
											</div>
										</div>
										<div class="col-12 md:col-4">
											<div class="mb-2 font-bold"> Last Name * </div>
											<div id="ctrl-last_name-holder">
												<InputText ref="ctrllast_name" v-model.trim="formData.last_name"
													label="Last Name" type="text" placeholder="Enter Last Name"
													class=" w-full" :class="getErrorClass('last_name')">
												</InputText>
											</div>
										</div>
										<div class="col-12 md:col-5">
											<div class="mb-2 font-bold"> Date of Birth * </div>
											<div id="ctrl-date_of_birth-holder">
												<Calendar :showButtonBar="true" class="w-full"
													:class="getErrorClass('date_of_birth')" dateFormat="yy-mm-dd"
													v-model="formData.date_of_birth" :showIcon="true"
													mask="YYYY-MM-DD" />
											</div>
										</div>
										<div class="col-12 md:col-3">
											<div class="mb-2 font-bold"> Gender * </div>
											<div id="ctrl-gender-holder">
												<Dropdown class="w-full" :class="getErrorClass('gender')"
													optionLabel="label" optionValue="value" ref="ctrlgender"
													v-model="formData.gender" :options="app.menus.genderItems"
													label="Gender" placeholder="Select ...">
												</Dropdown>
											</div>
										</div>
										<div class="col-12 md:col-4">
											<div class="mb-2 font-bold"> Country * </div>
											<div id="ctrl-country-holder">
												<Dropdown class="w-full" :class="getErrorClass('country')"
													optionLabel="label" optionValue="value" ref="ctrlcountry"
													:filter="true" v-model="formData.country"
													:options="app.menus.countryItems" label="Country"
													placeholder="Select ...">
												</Dropdown>
											</div>
										</div>
										<div class="col-12">
											<div class="mb-2 font-bold">Programme Of Study *</div>
											<div id="ctrl-programme_of_study-holder">
												<InputText ref="ctrlprogramme_of_study"
													v-model.trim="formData.programme_of_study"
													label="Programme Of Study" type="text"
													placeholder="Enter Programme Of Study" class=" w-full"
													:class="getErrorClass('programme_of_study')">
												</InputText>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold">Year Of Study *</div>
											<div id="ctrl-year_of_study-holder">
												<Dropdown class="w-full" :class="getErrorClass('year_of_study')"
													optionLabel="label" optionValue="value" ref="ctrlyear_of_study"
													v-model="formData.year_of_study"
													:options="app.menus.year_of_studyItems" label="Year Of Study"
													placeholder="Select ...">
												</Dropdown>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold">Whatsapp Number </div>
											<div id="ctrl-whatsapp_number-holder">
												<InputText ref="ctrlwhatsapp_number"
													v-model.trim="formData.whatsapp_number" label="Whatsapp Number"
													type="text" placeholder="Enter Whatsapp Number" pattern="^[0-9]+$"
													class=" w-full" minLength="10" maxLength="12"
													:class="getErrorClass('whatsapp_number')">
												</InputText>
											</div>
										</div>
										<div class="col-12">
											<div class="mb-2 font-bold"> Photo of a Student ID Card * </div>
											<div id="ctrl-id_card_photo-holder">
												<div class="mb-3">
													<Uploader :class="getErrorClass('id_card_photo')" :auto="true"
														:fileLimit="1" :maxFileSize="3000000"
														accept=".jpg,.png,.gif,.jpeg" :multiple="false"
														style="width:100%" label="Choose file or drop file here"
														upload-path="fileuploader/upload/id_card_photo"
														v-model="formData.id_card_photo"></Uploader>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="card nice-shadow-5">
									<div class="font-bold text-lg">
										Payment Information
									</div>
									<Divider />
									<div class="grid mt-3">
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Mobile Number * </div>
											<div class="ctrl-mobile_number-holder">
												<InputText ref="ctrlmobile_number" v-tooltip.top.focus="'024XXXXXXX'"
													v-model.trim="formData.mobile_number" label="Mobile Number"
													type="text" minLength="10" maxLength="10"
													placeholder="Enter Mobile Number" pattern="^[0-9]+$" class="w-full"
													:class="getErrorClass('mobile_number')">
												</InputText>
											</div>
										</div>
										<div class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Payment Method * </div>
											<div id="ctrl-payment_method-holder">
												<Dropdown class="w-full" :class="getErrorClass('payment_method')"
													optionLabel="label" optionValue="value" ref="ctrlpayment_method"
													v-model="formData.payment_method"
													:options="app.menus.paymentMethodItems" label="Payment Method"
													placeholder="Select ...">
												</Dropdown>
											</div>
										</div>
										<div v-if="formData.payment_method != card" class="col-12 md:col-6">
											<div class="mb-2 font-bold"> Network * </div>
											<div id="ctrl-network-holder">
												<Dropdown class="w-full" :class="getErrorClass('network')"
													optionLabel="label" optionValue="value" ref="ctrlnetwork"
													v-model="formData.network" :options="app.menus.networkItems"
													label="Network" placeholder="Select ...">
												</Dropdown>
											</div>
										</div>

										<div v-if="formData.payment_method == card"
											class="card nice-shadow-5 mx-2 mt-2">
											<div class="grid">
												<div class="col-12 md:col-7">
													<div class="mb-2 font-bold text-sm">
														Card Holder Name *
													</div>
													<div class="">
														<InputText ref="ctrlcard_holder"
															v-model.trim="formData.card_holder" label="Card Holder Name"
															type="text" pattern="^[A-Za-z-' ]+$" class="w-full"
															style="text-transform: uppercase"
															:class="getErrorClass('card_holder')">
														</InputText>
													</div>
												</div>
												<div class="col-12 md:col-5">
													<div class="mb-2 font-bold text-sm">
														Card Number *
													</div>
													<div class="">
														<InputMask ref="ctrlcard_number"
															v-model.trim="formData.card_number" label="Card Number"
															type="text" placeholder="9999 9999 9999 9999" class="w-full"
															mask="9999 9999 9999 9999"
															:class="getErrorClass('card_number')">
														</InputMask>
													</div>
												</div>

												<div class="col-12 md:col-12">
													<div class="grid">
														<div class="col-12 md:col-4">
															<div class="mb-2 font-bold text-sm">
																Expiry Month *
															</div>
															<div class="">
																<Dropdown class="w-full"
																	:class="getErrorClass('expiry_month')"
																	optionLabel="label" optionValue="value"
																	ref="ctrlexpiry_month"
																	v-model="formData.expiry_month"
																	:options="$menus.expiry_monthItems"
																	label="Expiry Month" placeholder="Select ...">
																</Dropdown>
															</div>
														</div>
														<div class="col-12 md:col-4">
															<div class="mb-2 font-bold text-sm">
																Expiry Year *
															</div>
															<div class="">
																<Dropdown class="w-full"
																	:class="getErrorClass('expiry_year')"
																	ref="ctrlexpiry_year" optionLabel="label"
																	optionValue="value" v-model="formData.expiry_year"
																	:options="$menus.expiry_yearItems"
																	label="Expiry Year" placeholder="Select ...">
																</Dropdown>
															</div>
														</div>
														<div class="col-12 md:col-4">
															<div class="mb-2 font-bold text-sm">
																CVV *
															</div>
															<div class="">
																<InputMask ref="ctrlcvv" v-model.trim="formData.cvv"
																	label="CVV" type="text" placeholder="999"
																	pattern="^[0-9]+$" class="w-full" mask="999"
																	:class="getErrorClass('cvv')">
																</InputMask>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-if="showSubmitButton" class="text-center my-3">
									<Button class="p-button-primary" type="submit" label="Continue" icon="pi pi-send"
										:loading="saving" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { computed, reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric, requiredIf } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from "@/composables/auth";
	import { useAddPage } from '@/composables/addpage.js';
	import { utils } from '@/utils.js';
	import { ApiService } from '@/services/api'
	const props = defineProps({
		pageName: {
			type: String,
			default: 'transactions',
		},
		routeName: {
			type: String,
			default: 'transactionsadd',
		},
		apiPath: {
			type: String,
			default: 'transactions/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Continue",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Payment',
		},
		msgAfterSave: {
			type: String,
			default: "Payment added successfully",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => { }
		},
	});
	const app = useApp();
	const auth = useAuth();

	const formDefaultValues = {
		payment_method: "",
		network: "",
		cvv: "",
		card_holder: "",
		card_number: "",
		expiry_month: "",
		expiry_year: "",
		first_name: "",
		middle_name: "",
		last_name: "",
		mobile_number: "",
		date_of_birth: "",
		gender: "",
		country: "",
		id_card_photo: "",
		programme_of_study: "",
		year_of_study: "",
		whatsapp_number: "",
		room_id: "",
		transaction_id: "",
		checking: false,
	};

	const card = 'DEBIT_CARD'
	const formData = reactive({ ...formDefaultValues });
	//form validation rules
	const rules = computed(() => {
		return {
			payment_method: { required },
			network: { required },
			mobile_number: { required },
			first_name: { required },
			middle_name: {},
			last_name: { required },
			date_of_birth: { required },
			gender: { required },
			country: { required },
			id_card_photo: { required },
			programme_of_study: { required },
			year_of_study: { required },
			whatsapp_number: {},
			cvv: {
				required: requiredIf(() => {
					const esit = formData.payment_method == card;
					return esit;
				}),
			},
			card_holder: {
				required: requiredIf(() => {
					const esit = formData.payment_method == card;
					return esit;
				}),
			},
			card_number: {
				required: requiredIf(() => {
					const esit = formData.payment_method == card;
					return esit;
				}),
			},
			expiry_month: {
				required: requiredIf(() => {
					const esit = formData.payment_method == card;
					return esit;
				}),
			},
			expiry_year: {
				required: requiredIf(() => {
					const esit = formData.payment_method == card;
					return esit;
				}),
			},
		}
	});
	const v$ = useVuelidate(rules, formData); // form validation
	const page = useAddPage({ props, formData, v$, beforeSubmit, afterSubmit });
	// perform custom validation before submit form
	// set custom form fields
	// return false if validation fails
	function beforeSubmit() {
		return true;
	}
	// after form submited to api
	// reset form data.
	// redirect to another page
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
		if (app.isDialogOpen()) {
			Object.assign(formData, { transaction_id: response.transaction_id })
		}
	}

	function updateForm() {
		Object.assign(formData, { room_id: props.pageData.room_id, mobile_number: auth.userMobileNumber })
	}

	async function confirmPayment() {
		try {
			Object.assign(formData, { checking: true })
			const url = `transactions/confirm/${formData.transaction_id}`;
			const response = await ApiService.get(url);
			Object.assign(formData, { checking: false })
			const record = response.data;

			if (record.transaction_status == "Pending") {
				app.flashMsg("Pending Payment", "Awaiting payment authorization and confirmation...", "warn");
			} else if (record.transaction_status == "Failed") {
				app.flashMsg("Payment Failed", "Transaction failed. Please try again", "error");
				Object.assign(formData, { transaction_id: "" })
				app.closeDialogs();
			} else {
				app.flashMsg("Payment Successful", "Transaction was successful. Room Successfully booked");
				app.navigateTo(`/`);
			}
		}
		catch (err) {
			Object.assign(formData, { checking: false })
			app.showPageRequestError(err);
		}
	}

	const { submitted, saving, pageReady } = toRefs(page.state);
	const { submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(() => {
		const pageTitle = "Payment";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		updateForm()
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped></style>